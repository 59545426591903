import '@hotwired/turbo-rails';
import 'channels';
import 'controllers';

import * as ActiveStorage from '@rails/activestorage';
import Rails from '@rails/ujs';
import Chart from 'chart.js/auto';
// import Turbolinks from 'turbolinks';
require('charges');

import('jquery');
import '@fortawesome/fontawesome-free/js/all';

require('trix');
require('@rails/actiontext');
import Sortable from 'sortablejs';


// window.jQuery = $;
// window.$ = $;

import 'chosen-js/chosen.css';
import 'chosen-js';
Rails.start();
ActiveStorage.start();

window.Noty = require('noty');
window.Dropzone = require('dropzone');
// Turbolinks.start();
// Turbo.session.drive = false;

import './bootstrap_custom.js';
import './stylesheets/home.scss';
import './stylesheets/application.scss';

$(document).on('turbo:load', () => {
  console.log('jquery loaded');
  $('.chosen-select').chosen({
    allow_single_deselect: true,
    no_results_text: 'No results matched',
    width: '100%',
  });

  const ctx1 = document.getElementById('page-views');
  if (ctx1) {
    const myChart1 = new Chart(ctx1, {
      type: 'line',
      data: {
        labels: JSON.parse(ctx1.dataset.labels),
        datasets: [
          {
            label: 'Page Views',
            data: JSON.parse(ctx1.dataset.data),
            borderWidth: 1,
          },
        ],
      },
    });
  }

  const ctx2 = document.getElementById('unique-page-views');
  if (ctx2) {
    const myChart2 = new Chart(ctx2, {
      type: 'line',
      data: {
        labels: JSON.parse(ctx2.dataset.labels),
        datasets: [
          {
            label: 'Unique Page Views',
            data: JSON.parse(ctx2.dataset.data),
            borderWidth: 1,
          },
        ],
      },
    });
  }

  // document.addEventListener('click', (e) => {
  //   let element = e.target.closest('.paragraph-content')
  //   if(!element) return;

  //   element.classList.add('d-none')
  //   element.nextElementSibling.classList.remove('d-none')
  // })

  // document.addEventListener('click', (e) => {
  //   if(!e.target.matches('.cancel')) return
  //   e.preventDefault();
  //   let element = e.target.closest('.paragraph-form')

  //   element.classList.add('d-none')
  //   element.previousElementSibling.classList.remove('d-none')
  // })

  // let elements = document.getElementById('elements')
  // Sortable.create(elements, { animation: 150 })
});

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

$(document).on('turbo:load', () => {
  let cardElement = document.querySelector('#card-element');

  if (cardElement !== null) {
    setupStripe();
  }

  let newCard = document.querySelector('#use-new-card');
  if (newCard !== null) {
    newCard.addEventListener('click', (event) => {
      event.preventDefault();
      document.querySelector('#payment-form').classList.remove('d-none');
      document.querySelector('#existing-card').classList.add('d-none');
    });
  }
});

function setupStripe() {
  const stripe_key = document
    .querySelector("meta[name='stripe-key']")
    .getAttribute('content');
  const stripe = Stripe(stripe_key);

  const elements = stripe.elements();
  const card = elements.create('card');
  card.mount('#card-element');

  var displayError = document.getElementById('card-errors');

  card.addEventListener('change', (event) => {
    if (event.error) {
      displayError.textContent = event.error.message;
    } else {
      displayError.textContent = '';
    }
  });

  const form = document.querySelector('#payment-form');
  let paymentIntentId = form.dataset.paymentIntent;
  let setupIntentId = form.dataset.setupIntent;

  console.log(paymentIntentId);

  if (paymentIntentId) {
    if (form.dataset.status == 'requires_action') {
      stripe
        .confirmCardPayment(paymentIntentId, {
          setup_future_usage: 'off_session',
        })
        .then((result) => {
          if (result.error) {
            displayError.textContent = result.error.message;
            form.querySelector('#card-details').classList.remove('d-none');
          } else {
            form.submit();
          }
        });
    }
  }

  form.addEventListener('submit', (event) => {
    event.preventDefault();

    let name = form.querySelector('#name_on_card').ariaValueMax;
    let data = {
      payment_method_data: {
        card: card,
        billing_details: {
          name: name,
        },
      },
    };

    // Complete a payment intent
    if (paymentIntentId) {
      stripe
        .confirmCardPayment(paymentIntentId, {
          payment_method: data.payment_method_data,
          setup_future_usage: 'off_session',
          save_payment_method: true,
        })
        .then((result) => {
          if (result.error) {
            displayError.textContent = result.error.message;
            form.querySelector('#card-details').classList.remove('d-none');
          } else {
            form.submit();
          }
        });
    } else if (setupIntentId) {
      // Updating a card or subscribing with a trial (using a SetupIntent)
      stripe
        .confirmCardSetup(setupIntentId, {
          payment_method: data.payment_method_data,
        })
        .then((result) => {
          if (result.error) {
            displayError.textContent = result.error.message;
          } else {
            addHiddenField(
              form,
              'payment_method_id',
              result.setupIntent.payment_method
            );
            form.submit();
          }
        });
    }
    // Subscribing with no trial
    else {
      data.payment_method_data.type = 'card';
      stripe.createPaymentMethod(data.payment_method_data).then((result) => {
        if (result.error) {
          displayError.textContent = result.error.message;
        } else {
          addHiddenField(form, 'payment_method_id', result.paymentMethod.id);
          form.submit();
        }
      });
    }
  });
}

function addHiddenField(form, name, value) {
  let hiddenInput = document.createElement('input');
  hiddenInput.setAttribute('type', 'hidden');
  hiddenInput.setAttribute('name', name);
  hiddenInput.setAttribute('value', value);
  form.appendChild(hiddenInput);
}
